<template>
  <div class="me-wrapper-box">
    <div class="me-wrapper">
      <div class="me-banner">
        <div class="_back" @click="$router.go(-1)"><img src="../status/img/icon_back.png" /></div>
        <div class="_img"><img src="http://pic.eaworks.cn/scbank/uploads/2023316/7234949.png" /></div>
      </div>
      <div class="jk-banner">
        <ul>
          <li class="_img" v-for="item of moreList" @click="comingSoon=true"  >
            <img :src="item.image"  />
          </li>
        </ul>
      </div>
    </div>
    <Footer :step="1" />
    <div class="coming-soon" v-if="comingSoon" >
      <div class="_content">
        <div class="_window"><img src="../status/img/icon_coming.png" /></div>
        <div class="_closed" @click="comingSoon = false"><img src="../status/img/icon_closed.png" /></div>
      </div>
      <div class="_bg"></div>
    </div>
  </div>
</template>

<script>
import Cookies from 'js-cookie';
import Footer from './components/footer'

export default {
  mixins: [],
  components: {Footer},
  data() {
    return {
      comingSoon: false,
      moreList:[{
       image:"http://pic.eaworks.cn/scbank/uploads/2023221/9369663.png"
     },{
       image:"http://pic.eaworks.cn/scbank/uploads/2023221/7824832.png"
     },{
       image:"http://pic.eaworks.cn/scbank/uploads/2023221/8507115.png"
     },{
       image:"http://pic.eaworks.cn/scbank/uploads/2023221/6847494.png"
     }]
    };
  },

  created() {
    this.id = this.$route.query.id;
  },

  mounted() {
this.getCategoryDetail();
  },

  methods: {
    async getCategoryDetail() {
      // 获取分类列表
      let {data,code} = await this.$ajax.gateway("/api/api_get_category_by_id", {
        "METHOD": "get",
         id: this.id
      });
      this.categoryInfo = data.info;
      this.goodsList = data.goods;
    }
  }
};
</script>

<style lang="less" scoped>
  @import "../status/css/page.less";

  .jk-banner {
    padding: 0 0.5rem;
    ul li {
      margin-top: 0.2rem;
    }
  }
  .jk-foot {
    width: 100%;
    padding: 0.6rem 0 0.3rem;
    position: absolute;
    bottom: 0;
    left: 0;
  }
  .coming-soon {
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.45);
    ._content {
      width: 100%;
      position: absolute;
      top: 2rem;
      left: 0;
      ._window {
        padding: 0 0.75rem;
        img {
          display: block;
          width: 100%;
        }
      }
      ._closed {
        padding: 0.4rem 0;
        text-align: center;
        img {
          width: 1.2rem;
        }
      }
    }
  }
</style>
